import {dial} from './diagram'

const animItems = document.querySelectorAll('._anim-item')

if (animItems.length > 0) {
    window.addEventListener("scroll", animOnScroll)

    function animOnScroll() {
        for (let index = 0; index < animItems.length; index++) {
            const animItem = animItems[index]
            const animItemHeight = animItem.offsetHeight
            const animItemOffset = offset(animItem).top
            const animStart = 8

            let animItemPoint = window.innerHeight - animItemHeight / animStart


            if (animItemHeight > window.innerHeight) {
                animItemPoint = window.innerHeight - window.innerHeight / animStart
            }

            if ((pageYOffset > animItemOffset - animItemPoint) && pageYOffset < (animItemOffset + animItemHeight)) {
                if (!dial.activeStatus) {
                    dial.animateStart();
                }
            }
        }
        // animItem.classList.add('_anim-active')

        if (pageYOffset > 700) {
            document.getElementById('navbar-menu').style.display = 'none'
            document.getElementById('navbar-burger').setAttribute('data-open', 'false')
        }

        if (pageYOffset > 800) {
            document.querySelectorAll('.upper')[0].classList.add('_anim-active')
        } else {
            document.querySelectorAll('.upper')[0].classList.remove('_anim-active')
        }
    }

    function offset(el) {
        const rect = el.getBoundingClientRect()
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop

        return {
            top: rect.top + scrollTop,
            left: rect.left + scrollLeft,
        }
    }
}