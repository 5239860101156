const trigger = document.getElementById('navbar-burger')
const menu = document.getElementById('navbar-menu')

function handlerTrigger() {
    if (trigger.getAttribute('data-open') === 'false') {
        trigger.setAttribute('data-open', 'true')
        menu.style.display = 'block'
    } else {
        trigger.setAttribute('data-open', 'false')
        menu.style.display = 'none'
    }
}

trigger.onclick = handlerTrigger