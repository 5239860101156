const screenWidth = window.screen.width
const containers = document.getElementsByClassName("chart");

if (screenWidth <= 500) {
    containers[0].setAttribute('data-size', '360')
}
let Dial = function (container) {
    this.container = container;
    this.size = this.container.dataset.size;
    this.strokeWidth = this.size / 2;
    this.value = this.container.dataset.value;
    this.svg;
    this.defs;
    this.slice;
    this.overlay;
    this.text;
    // this.activeStatus;
    this.create();
};

Dial.prototype.activeStatus = false

Dial.prototype.create = function () {
    this.createSvg();
    this.createDefs();
    this.createSlice();
    // this.createOverlay();
    this.createText();
    this.container.appendChild(this.svg);
};

Dial.prototype.createSvg = function () {
    let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute('width', this.size + 'px');
    svg.setAttribute('height', this.size + 'px');
    this.svg = svg;
};

Dial.prototype.createDefs = function () {
    let defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
    let linearGradient = document.createElementNS("http://www.w3.org/2000/svg", "linearGradient");
    linearGradient.setAttribute('id', 'gradient');
    let stop1 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
    stop1.setAttribute('stop-color', '#C12878');
    stop1.setAttribute('offset', '0%');
    linearGradient.appendChild(stop1);
    // let stop2 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
    // stop2.setAttribute('stop-color', '#C12878');
    // stop2.setAttribute('offset', '100%');
    // linearGradient.appendChild(stop2);
    // let linearGradientBackground = document.createElementNS("http://www.w3.org/2000/svg", "linearGradient");
    // linearGradientBackground.setAttribute('id', 'gradient-background');
    // stop1 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
    // stop1.setAttribute('stop-color', 'rgba(0, 0, 0, 0.2)');
    // stop1.setAttribute('offset', '0%');
    // linearGradientBackground.appendChild(stop1);
    // stop2 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
    // stop2.setAttribute('stop-color', 'rgba(0, 0, 0, 0.05)');
    // stop2.setAttribute('offset', '100%');
    // linearGradientBackground.appendChild(stop2);
    defs.appendChild(linearGradient);
    // defs.appendChild(linearGradientBackground);
    this.svg.appendChild(defs);
    this.defs = defs;
};

Dial.prototype.createSlice = function () {
    let slice = document.createElementNS("http://www.w3.org/2000/svg", "path");
    slice.setAttribute('fill', 'none');
    slice.setAttribute('stroke', 'url(#gradient)');
    slice.setAttribute('stroke-width', this.strokeWidth);
    slice.setAttribute('transform', 'translate(' + this.strokeWidth / 2 + ',' + this.strokeWidth / 2 + ')');
    slice.setAttribute('class', 'animate-draw');
    this.svg.appendChild(slice);
    this.slice = slice;
};

Dial.prototype.createText = function () {
    let fontSize = this.size / 6.25;
    let text = document.createElementNS("http://www.w3.org/2000/svg", "text");
    text.setAttribute('x', this.size / 2.5);
    text.setAttribute('y', this.size / 2.5 - 10);
    text.setAttribute('font-size', fontSize);
    text.setAttribute('fill', '#FFFFFF');
    text.setAttribute('text-anchor', 'middle');
    text.innerHTML = 0 + '<tspan font-size="' + fontSize + '" dy="0">%</tspan>';
    this.svg.appendChild(text);
    this.text = text;

};

Dial.prototype.animateStart = function () {
    let v = 0;
    let self = this;
    let intervalOne = setInterval(function () {
        let p = +(v / self.value).toFixed(2);
        let a = p < 0.95 ? 2 - 2 * p : 0.05;
        v += a;
        // Stop
        if (v >= +self.value) {
            v = self.value;
            clearInterval(intervalOne);
        }
        self.setValue(v);
    }, 6);
    this.activeStatus = true
};

Dial.prototype.polarToCartesian = function (centerX, centerY, radius, angleInDegrees) {
    let angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;
    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians) };

};

Dial.prototype.describeArc = function (x, y, radius, startAngle, endAngle) {
    let start = this.polarToCartesian(x, y, radius, endAngle);
    let end = this.polarToCartesian(x, y, radius, startAngle);
    let largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    let d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].
    join(" ");
    return d;
};

Dial.prototype.setValue = function (value) {
    let c = value / 100 * 360;
    if (c === 360)
        c = 359.99;
    let xy = this.size / 2 - this.strokeWidth / 2;
    let d = this.describeArc(xy, xy, xy, 150, 150 + c);
    this.slice.setAttribute('d', d);
    let tspanSize = this.size /  6.25;
    this.text.innerHTML = Math.floor(value) + '<tspan font-size="' + tspanSize + '" dy="0">%</tspan>';
    // this.secondText.innerHTML = 'Наших клиентов обратились по рекомендации';
};

//
// Usage
//

export let dial = new Dial(containers[0]);
// dial.animateStart();